import { apiSlice } from "../api/apiSlice";

export const acSchedule = apiSlice.injectEndpoints({
  tagTypes: ["ac"],
  endpoints: (builder) => ({
    getAcScheduleList: builder.query({
      query: (branchId) => `/apiV2/ac/branch-ac-schedules/${branchId}`,
      providesTags: ["ac"],
    }),
    createAcSchedule: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/ac-schedule",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ac"],
    }),
    deleteScheduleSpecificTime: builder.mutation({
      query: ({ scheduleId }) => ({
        url: `/apiV2/ac/ac-schedules-delete/${scheduleId}`,
        method: "DELETE",
        // body: data,
      }),
      invalidatesTags: ["ac"],
    }),
    // updateFish: builder.mutation({
    //   query: (data) => ({
    //     url: "/apiV2/fish",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["ac"],
    // }),
    updateToggleIndividualSchedule: builder.mutation({
      query: ({ scheduleId }) => ({
        url: `/apiV2/ac/ac-schedules-status/${scheduleId}`, // Assuming `data` contains an `id` field
        method: "PUT",
        // body: data,
      }),
      invalidatesTags: ["ac"], // Invalidate any cache related to 'ac' to refresh it
    }),
    editScheduleData: builder.query({
      query: (acId) => `/apiV2/ac/ac-schedules/${acId}`,
      providesTags: ["ac"],
    }),
  }),
});

export const {
  useGetAcScheduleListQuery,
  useCreateAcScheduleMutation,
  useDeleteScheduleSpecificTimeMutation,
  useUpdateToggleIndividualScheduleMutation,
  useEditScheduleDataQuery,
  useLazyEditScheduleDataQuery,
  //   useUpdateFishMutation,
} = acSchedule;
