import { apiSlice } from "../api/apiSlice";

export const acUserRoleApi = apiSlice.injectEndpoints({
  tagTypes: ["user"],
  endpoints: (builder) => ({
    getUserList: builder.query({
      query: () => "/apiV2/user-list",
      providesTags: ["user"],
    }),
    // createAcBranch: builder.mutation({
    //   query: (data) => ({
    //     url: "/apiV2/ac/branch-save",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["branch"],
    // }),
    updateUserRole: builder.mutation({
      query: (data) => ({
        url: "/apiV2/user-role",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
  }),
});

export const {
  useGetUserListQuery,
  // useCreateAcBranchMutation,
  useUpdateUserRoleMutation,
} = acUserRoleApi;
