import { apiSlice } from "../api/apiSlice";

export const Pksfw2MotorControlApi = apiSlice.injectEndpoints({
  tagTypes: ["pksfw2"],
  endpoints: (builder) => ({
    aeratorOnOff: builder.mutation({
      query: (data) => ({
        url: "/apiV2/aerator-switch",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["pksfw2"],
    }),
    pksfw2MotorOnOff: builder.mutation({
      query: (data) => ({
        url: "/apiV2/motor-switch",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["pksfw2"],
    }),
    getPksfw2MotorStatus: builder.query({
      query: () => "/apiV2/pksfw2-status",
      providesTags: ["pksfw2"],
    }),
  }),
});

export const {
  useAeratorOnOffMutation,
  useGetPksfw2MotorStatusQuery,
  usePksfw2MotorOnOffMutation,
} = Pksfw2MotorControlApi;
