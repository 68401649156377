import { apiSlice } from "../api/apiSlice";

export const acPeripheralCreate = apiSlice.injectEndpoints({
  tagTypes: ["peripheral"],
  endpoints: (builder) => ({
    getAcPeripheralList: builder.query({
      query: () => "/apiV2/ac/peripheral-list",
      providesTags: ["peripheral"],
    }),
    createAcPeripheral: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/peripheral-save",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["peripheral"],
    }),
    updateAcPeripheral: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/peripheral-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["peripheral"],
    }),
  }),
});

export const {
  useGetAcPeripheralListQuery,
  useCreateAcPeripheralMutation,
  useUpdateAcPeripheralMutation,
} = acPeripheralCreate;
