import { apiSlice } from "../api/apiSlice";

export const acMainDashboardApi = apiSlice.injectEndpoints({
  tagTypes: ["ac-main-dashboard"],
  endpoints: (builder) => ({
    getAcMainDashboardData: builder.query({
      query: () => `/apiV2/ac/branch-dashboard`,
      providesTags: ["ac-main-dashboard"],
    }),
    // createAcSchedule: builder.mutation({
    //   query: (data) => ({
    //     url: "/apiV2/ac/ac-schedule",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["ac"],
    // }),
    // updateFish: builder.mutation({
    //   query: (data) => ({
    //     url: "/apiV2/fish",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["ac"],
    // }),
  }),
});

export const {
  //   useGetAssignedAcListQuery,
  useGetAcMainDashboardDataQuery,
  //   useCreateAcScheduleMutation,

  //   useUpdateFishMutation,
} = acMainDashboardApi;
