import { apiSlice } from "../api/apiSlice";

export const acBranchCreateApi = apiSlice.injectEndpoints({
  tagTypes: ["branch"],
  endpoints: (builder) => ({
    getAcBranchList: builder.query({
      query: () => "/apiV2/ac/branch-list",
      providesTags: ["branch"],
    }),
    // createAcSchedule: builder.mutation({
    //   query: (data) => ({
    //     url: "/apiV2/ac/ac-schedule",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["ac"],
    // }),
    // updateFish: builder.mutation({
    //   query: (data) => ({
    //     url: "/apiV2/fish",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["ac"],
    // }),
  }),
});

export const {
  useGetAcBranchListQuery,
  //   useCreateAcScheduleMutation,

  //   useUpdateFishMutation,
} = acBranchCreateApi;
