import React from "react";
import styles from "./acSchedule.module.css";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import AcTimeScheduleCard from "./AcTimeScheduleCard";

function AcScheduleIndividualCard({ scheduleData, onEditButtonClick }) {
  const acId = scheduleData?.ac_id;
  const daysArray = [...scheduleData?.days];
  const dayMapping = {
    Sat: "1",
    Sun: "2",
    Mon: "3",
    Tue: "4",
    Wed: "5",
    Thu: "6",
    Fri: "7",
  };

  const dayNames = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];

  const onEditHandleClick = () => {
    onEditButtonClick(acId);
  };

  return (
    <div>
      <div
        className={`${styles.card} ${styles.card_data_section} theme-background theme-text`}
      >
        <div className={`${styles.title_portion}`}>
          <div className={`${styles.title} theme-text`}>
            {scheduleData?.ac_name}
          </div>
          {/* <div className={`${styles.toggle}`}>
            <div>
              <label class="switch">
                <input type="checkbox" />
                <span class="slider round"></span>
              </label>
            </div>
          </div> */}
          <div className={`${styles.edit}`} onClick={onEditHandleClick}>
            <div className={`${styles.icon_container}`}>
              <FaEdit />
            </div>
            <div className="text" style={{ fontWeight: "bold" }}>
              EDIT
            </div>
          </div>
        </div>
        <div className={`${styles.day_container}`}>
          {dayNames.map((day) => (
            <div
              key={day}
              className={`${styles.day_card} ${
                daysArray.includes(dayMapping[day]) ? styles.day_active : ""
              }`}
            >
              {day}
            </div>
          ))}
          {/* <div className={`${styles.day_card}`}>Sat</div>
          <div className={`${styles.day_card}`}>Sun</div>
          <div className={`${styles.day_card} ${styles.day_active}`}>Mon</div>
          <div className={`${styles.day_card}`}>Tue</div>
          <div className={`${styles.day_card}`}>Wed</div>
          <div className={`${styles.day_card} ${styles.day_active}`}>Thu</div>
          <div className={`${styles.day_card}`}>Fri</div> */}
        </div>

        <div className={`${styles.mode_container}`}>
          <div className={`${styles.mode_text}`}>Mode</div>
          <div className={`${styles.mode_type_container}`}>
            <div
              className={`${styles.mode_card} ${
                scheduleData?.mode == "heat" ? styles.mode_card_active : ""
              }`}
            >
              Heat
            </div>
            <div
              className={`${styles.mode_card} ${
                scheduleData?.mode == "cool" ? styles.mode_card_active : ""
              }`}
            >
              Cool
            </div>
            <div
              className={`${styles.mode_card} ${
                scheduleData?.mode == "fan" ? styles.mode_card_active : ""
              }`}
            >
              Fan
            </div>
            <div
              className={`${styles.mode_card} ${
                scheduleData?.mode == "dry" ? styles.mode_card_active : ""
              }`}
            >
              Dry
            </div>
          </div>
        </div>
        {/* repeatative section starts */}
        {}
        {scheduleData?.schedules?.map((scheduleTimeData) => {
          return <AcTimeScheduleCard scheduleTimeData={scheduleTimeData} />;
        })}

        {/* repeatative section ends */}
      </div>
    </div>
  );
}

export default AcScheduleIndividualCard;
