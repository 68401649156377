import { apiSlice } from "../api/apiSlice";

export const acCreateApi = apiSlice.injectEndpoints({
  tagTypes: ["ac-list"],
  endpoints: (builder) => ({
    getAssignedAcList: builder.query({
      query: (branchId) =>
        `/apiV2/ac/peripheral-assign-list?branch_id=${branchId}`,
      providesTags: ["ac-list"],
    }),
    // createAcSchedule: builder.mutation({
    //   query: (data) => ({
    //     url: "/apiV2/ac/ac-schedule",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["ac"],
    // }),
    // updateFish: builder.mutation({
    //   query: (data) => ({
    //     url: "/apiV2/fish",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["ac"],
    // }),
  }),
});

export const {
  useGetAssignedAcListQuery,
  //   useCreateAcScheduleMutation,

  //   useUpdateFishMutation,
} = acCreateApi;
