import { apiSlice } from "../api/apiSlice";

export const acReportApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    acReportDataFetch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/ac-report",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  //   useGetAcPeripheralListQuery,
  //   useCreateAcPeripheralMutation,
  useAcReportDataFetchMutation,
} = acReportApi;
