import { apiSlice } from "../api/apiSlice";

export const acCompanyCreate = apiSlice.injectEndpoints({
  tagTypes: ["company"],
  endpoints: (builder) => ({
    getAcCompanyList: builder.query({
      query: () => "/apiV2/ac/company-list",
      providesTags: ["company"],
    }),
    createAcCompany: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/company-save",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["company"],
    }),
    updateAcCompany: builder.mutation({
      query: (data) => ({
        url: "/apiV2/company-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["company"],
    }),
  }),
});

export const {
  useGetAcCompanyListQuery,
  useCreateAcCompanyMutation,
  useUpdateAcCompanyMutation,
} = acCompanyCreate;
