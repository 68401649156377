import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import useTitle from "../../../hooks/useTitle";
import AcCard from "../ac-dashboard/AcCard";
import styles from "./acSchedule.module.css";
import AcScheduleCards from "./AcScheduleCards";
import { Modal, Button } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";

import { useCreateAcScheduleMutation } from "../../../features/ac/acSchedule";
import { useGetAssignedAcListQuery } from "../../../features/ac/acCreateApi";
import { useGetAcBranchListQuery } from "../../../features/ac/acBranchCreateApi";
import { useLazyEditScheduleDataQuery } from "../../../features/ac/acSchedule";

function AcSchedule() {
  useTitle("Ac Schedule");
  const theme = useContext(ThemeContext);

  const [show, setShow] = useState(false);
  const [reseponseMessage, setResponseMessage] = useState();
  const [updateFormData, setUpdateFormData] = useState({});
  const [activeDays, setActiveDays] = useState([]);
  const [activeMode, setActiveMode] = useState("cool");
  const [selectedBranchId, setSelectedBranchId] = useState();
  const [scheduleAddFormBranchId, setScheduleAddFormBranchId] = useState();
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [scheduleData, setScheduleData] = useState({
    schedule_1: { start_time: null, end_time: null, set_temp: null },
    schedule_2: { start_time: null, end_time: null, set_temp: null },
    schedule_3: { start_time: null, end_time: null, set_temp: null },
    schedule_4: { start_time: null, end_time: null, set_temp: null },
  });

  // const [assignedAcList, setAssignedAcList] = useState([]);
  // const [activeTab, setActiveTab] = useState("branchSchedule"); // Initial active tab

  // const handleTabClick = (tabName) => {
  //   setActiveTab(tabName);
  // };

  const { data: acList, isLoading: acListLoading } = useGetAssignedAcListQuery(
    scheduleAddFormBranchId
  );
  const { data: acBranchList, isLoading: branchListLoading } =
    useGetAcBranchListQuery();
  const [createAcSchedule] = useCreateAcScheduleMutation();
  const [editScheduleData, results] = useLazyEditScheduleDataQuery();

  const dayMapping = {
    Sat: "1",
    Sun: "2",
    Mon: "3",
    Tue: "4",
    Wed: "5",
    Thu: "6",
    Fri: "7",
  };
  const modeMapping = {
    Heat: "heat",
    Cool: "cool",
    Fan: "fan",
    Dry: "dry",
  };
  const handleDayClick = (day) => {
    setResponseMessage("");
    const dayValue = dayMapping[day];
    setActiveDays(
      (prevActiveDays) =>
        prevActiveDays.includes(dayValue)
          ? prevActiveDays.filter((activeDay) => activeDay !== dayValue) // Unselect if already selected
          : [...prevActiveDays, dayValue] // Select if not already selected
    );
  };

  const handleModeClick = (mode) => {
    setResponseMessage("");
    setActiveMode(modeMapping[mode]);
  };

  const handleClose = () => {
    setShow(false);
    setResponseMessage("");
    setActiveDays([]);
    setScheduleData({
      schedule_1: { start_time: null, end_time: null, set_temp: null },
      schedule_2: { start_time: null, end_time: null, set_temp: null },
      schedule_3: { start_time: null, end_time: null, set_temp: null },
      schedule_4: { start_time: null, end_time: null, set_temp: null },
    });
    setUpdateFormData({});
    setActiveMode("cool");
  };
  const handleShow = () => {
    setCreateFormSelector(true);
    setShow(true);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
    setActiveDays([]);
    setScheduleData({
      schedule_1: { start_time: null, end_time: null, set_temp: null },
      schedule_2: { start_time: null, end_time: null, set_temp: null },
      schedule_3: { start_time: null, end_time: null, set_temp: null },
      schedule_4: { start_time: null, end_time: null, set_temp: null },
    });
    setUpdateFormData({});
    setActiveMode("cool");
  };

  const dataChangeHandler = (e) => {
    setResponseMessage("");
    setUpdateFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };
  // console.log(updateFormData);

  const handleChange = (schedule, field, value) => {
    setResponseMessage("");
    setScheduleData((prevState) => ({
      ...prevState,
      [schedule]: {
        ...prevState[schedule],
        [field]: value,
      },
    }));
  };
  // console.log(scheduleData);
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(
    //   JSON.stringify({
    //     ...updateFormData,
    //     day: activeDays,
    //     ...scheduleData,
    //     mode: activeMode,
    //   })
    // );

    createAcSchedule({
      ...updateFormData,
      day: activeDays,
      ...scheduleData,
      mode: activeMode,
    })
      .unwrap()
      .then((payload) => setResponseMessage(payload?.message))
      .catch((error) => setResponseMessage(error?.status));
  };

  const handleScheduleEdit = async (acId) => {
    setCreateFormSelector(false);
    // console.log("Edit", acId);

    if (acId) {
      try {
        // Trigger the data fetch using the lazy query function
        const payload = await editScheduleData(acId).unwrap();

        // Update the state with the fetched data
        setScheduleData({
          schedule_1: {
            start_time: payload.schedule_1?.start_time || null,
            end_time: payload.schedule_1?.end_time || null,
            set_temp: payload.schedule_1?.set_temp || null,
          },
          schedule_2: {
            start_time: payload.schedule_2?.start_time || null,
            end_time: payload.schedule_2?.end_time || null,
            set_temp: payload.schedule_2?.set_temp || null,
          },
          schedule_3: {
            start_time: payload.schedule_3?.start_time || null,
            end_time: payload.schedule_3?.end_time || null,
            set_temp: payload.schedule_3?.set_temp || null,
          },
          schedule_4: {
            start_time: payload.schedule_4?.start_time || null,
            end_time: payload.schedule_4?.end_time || null,
            set_temp: payload.schedule_4?.set_temp || null,
          },
        });
        setActiveMode(payload?.mode || "cool");
        setActiveDays([...payload?.day] || []);
        setUpdateFormData({
          branch_id: payload?.branch_id || "",
          ac_id: payload?.ac_id || "",
          // Add other fields if needed
        });
        setScheduleAddFormBranchId(payload?.branch_id);
      } catch (error) {
        console.error("Failed to fetch schedule data:", error);
      }
    }

    // Show the modal after setting the data
    setShow(true);
  };
  return (
    <div>
      <div className={`container-fluid ${theme}`}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className="theme-text"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              paddingBottom: "1rem",
            }}
          >
            Schedule On Off
          </div>
          <div
            className="col-md-2 "
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              paddingBottom: "1rem",
              //   marginRight: "-10px",
            }}
          >
            <button
              type="submit"
              class="btn btn-primary col-md-12"
              onClick={handleShow}
            >
              {" "}
              Add Schedule
            </button>
          </div>
        </div>
        {/* <Modal show={show} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
            <div style={{ color: "green" }}>Successfully Created</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        </Modal> */}
        <Modal
          show={show}
          onHide={handleClose}
          classname={`${theme} `}
          centered
          onExited={handleModalOutsideClick}
          size="lg"
        >
          <div className={`${theme}`}>
            <Modal.Header
              className="theme-background theme-text"
              style={{ borderRadius: "0px" }}
            >
              <Modal.Title>
                {createFormSelector ? "Add Schedule" : "Update Schedule"}
              </Modal.Title>
              <div style={{ color: "green" }}>{reseponseMessage}</div>
              {/* <div style={{ color: "green" }}>
                Schedule created successfully
              </div> */}

              <RxCross2 onClick={handleModalOutsideClick} />
            </Modal.Header>
            <Modal.Body className="theme-background theme-text">
              {" "}
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
                <div>
                  <div>
                    <form onSubmit={handleSubmit}>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <label className="col-md-3"> Select Branch</label>
                        <div
                          style={{
                            paddingLeft: "12px",
                            width: "100%",
                            paddingRight: "12px",
                          }}
                        >
                          <select
                            className="form-select"
                            name="branch_id"
                            value={updateFormData.branch_id || ""}
                            onChange={(e) => {
                              dataChangeHandler(e);
                              setScheduleAddFormBranchId(e.target.value);
                            }}
                            required
                            disabled={!createFormSelector}
                          >
                            <option value="" selected>
                              Select Branch
                            </option>
                            {acBranchList?.data?.map((branchList) => {
                              return (
                                <option value={branchList.id}>
                                  {branchList.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div
                        className="date-search-submit-btn"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <label className="col-md-3">Select Ac</label>
                        <div
                          style={{
                            paddingLeft: "12px",
                            width: "100%",
                            paddingRight: "12px",
                          }}
                        >
                          {/* <select
                            className="form-select"
                            name="ac_id"
                            value={updateFormData.ac_id || ""}
                            onChange={dataChangeHandler}
                            required
                            disabled={!scheduleAddFormBranchId || acListLoading}
                          >
                            <option value="" selected>
                              Select Ac
                            </option>
                            {acList?.data?.map((acList) => {
                              return (
                                <option value={acList.id}>
                                  {acList.peripheral_name}
                                </option>
                              );
                            })}
                          </select> */}
                          {scheduleAddFormBranchId && !acListLoading ? (
                            <select
                              className="form-select"
                              name="ac_id"
                              value={updateFormData.ac_id || ""}
                              onChange={dataChangeHandler}
                              required
                              disabled={!createFormSelector}
                            >
                              <option value="">Select Ac</option>
                              {acList?.data?.map((ac) => (
                                <option value={ac.id} key={ac.id}>
                                  {ac.peripheral_name}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <select className="form-select" disabled>
                              <option>Select Ac</option>
                            </select>
                          )}
                        </div>
                      </div>
                      <div
                        className="form-group date-search-submit-btn"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <label className="col-md-3">Select Day</label>
                        <div className="col-md-9">
                          <div className={`${styles.day_container}`}>
                            {Object.keys(dayMapping).map((day) => (
                              <div
                                key={day}
                                className={`${styles.day_card} ${
                                  activeDays.includes(dayMapping[day])
                                    ? styles.day_active
                                    : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDayClick(day)}
                              >
                                {day}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {[1, 2, 3, 4].map((i) => (
                        <div
                          key={i}
                          className="date-search-submit-btn"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label className="col-md-3">Schedule {i}</label>
                          <div
                            style={{
                              overflow: "scroll",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div className="form-group col-md-4">
                              <label className="theme-text">Start Time</label>
                              <input
                                type="time"
                                className="form-control"
                                value={
                                  scheduleData[`schedule_${i}`]?.start_time
                                }
                                onChange={(e) =>
                                  handleChange(
                                    `schedule_${i}`,
                                    "start_time",
                                    e.target.value
                                  )
                                }
                                required={i == 1}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label className="theme-text">End Time</label>
                              <input
                                type="time"
                                className="form-control"
                                value={scheduleData[`schedule_${i}`]?.end_time}
                                onChange={(e) =>
                                  handleChange(
                                    `schedule_${i}`,
                                    "end_time",
                                    e.target.value
                                  )
                                }
                                required={i == 1}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label className="theme-text">Temp</label>
                              <select
                                className="form-select"
                                value={scheduleData[`schedule_${i}`]?.set_temp}
                                onChange={(e) =>
                                  handleChange(
                                    `schedule_${i}`,
                                    "set_temp",
                                    e.target.value
                                  )
                                }
                                required={i == 1}
                              >
                                <option value="">Select Temp</option>
                                {[
                                  16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                                  27, 28, 29, 30, 31, 32,
                                ].map((value) => {
                                  return (
                                    <option value={value}>{value}°C</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div
                        className="form-group date-search-submit-btn"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <label className="col-md-3">Select Mode</label>
                        <div className="col-md-9">
                          <div className={`${styles.mode_type_container}`}>
                            {Object.keys(modeMapping).map((mode) => (
                              <div
                                key={modeMapping[mode]}
                                className={`${styles.mode_card} ${
                                  activeMode === modeMapping[mode]
                                    ? styles.mode_card_active
                                    : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleModeClick(mode)}
                              >
                                {mode}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Button type="submit">Submit</Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
        {/* <div className="row">
          <div
            className={`theme-background theme-text col-md-6 ${styles.menu_card}`}
            style={{
              textAlign: "center",
              padding: "10px",
              fontSize: "18px",
              opacity: activeTab === "branchSchedule" ? "1.0" : "0.5",
            }}
            onClick={() => handleTabClick("branchSchedule")}
          >
            Branch Schedule
          </div>
          <div
            className={`theme-background theme-text col-md-6 ${styles.menu_card}`}
            style={{
              textAlign: "center",
              padding: "10px",
              fontSize: "18px",
              opacity: activeTab === "acSchedule" ? "1.0" : "0.5",
            }}
            onClick={() => handleTabClick("acSchedule")}
          >
            AC Schedule
          </div>
        </div> */}
        {/* {activeTab === "acSchedule" ? <AcScheduleCards /> : "Branch Schedule"} */}
        <div className="card theme-background">
          <div
            className="theme-text"
            style={{ textAlign: "center", padding: "10px", fontSize: "18px" }}
          >
            Ac Schedule
          </div>
        </div>
        <div
          className="col-md-3 date-search-submit-btn "
          style={{ marginLeft: "auto", padding: "0px" }}
        >
          <select
            className="form-select"
            aria-label="Default select example"
            // value={sensor_id}
            onChange={(e) => setSelectedBranchId(e.target.value)}
          >
            <option value="" selected>
              Select Branch
            </option>
            {acBranchList?.data?.map((branchList) => {
              return <option value={branchList.id}>{branchList.name}</option>;
            })}
          </select>
        </div>{" "}
        <AcScheduleCards
          onEditButtonClick={handleScheduleEdit}
          selectedBranchId={selectedBranchId}
        />
      </div>
    </div>
  );
}

export default AcSchedule;
