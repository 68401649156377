import { apiSlice } from "../api/apiSlice";

export const acBranchCreate = apiSlice.injectEndpoints({
  tagTypes: ["branch"],
  endpoints: (builder) => ({
    getAcBranchList: builder.query({
      query: () => "/apiV2/ac/branch-list",
      providesTags: ["branch"],
    }),
    createAcBranch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/branch-save",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["branch"],
    }),
    updateAcBranch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/branch-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["branch"],
    }),
  }),
});

export const {
  useGetAcBranchListQuery,
  useCreateAcBranchMutation,
  useUpdateAcBranchMutation,
} = acBranchCreate;
