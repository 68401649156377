import React, { useState, useEffect } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";
// import "./styles/motorControl.css";
// import { useMotorOnOffMutation } from "../../features/motor-contorl/motorControlApi";
// import { useGetMotorStatusQuery } from "../../features/motor-contorl/motorControlApi";
import Swal from "sweetalert2";
import useTitle from "../../hooks/useTitle";

// import { useSensorSwitchMutation } from "../../features/sensor-switch/sensorSwitchApi";
// import { useGetSensorSwitchStatusQuery } from "../../features/sensor-switch/sensorSwitchApi";

import { useAeratorOnOffMutation } from "../../features/pksfw2-motor-control/Pksfw2MotorControlApi";
import { usePksfw2MotorOnOffMutation } from "../../features/pksfw2-motor-control/Pksfw2MotorControlApi";
import { useGetPksfw2MotorStatusQuery } from "../../features/pksfw2-motor-control/Pksfw2MotorControlApi";

function Pksfw2MotorControl() {
  useTitle("Motor Control");
  const theme = useContext(ThemeContext);
  // const [onOffServerStatus, setOnOffServerStatus] = useState(false);

  const [onOffAeratorServerStatus, setOnOffAeratorServerStatus] =
    useState(false);

  const [onOffMotorServerStatus, setOnOffMotorServerStatus] = useState(false);

  // const [sensorSwitch] = useSensorSwitchMutation();

  const [aeratorOnOff] = useAeratorOnOffMutation();
  const [pksfw2MotorOnOff] = usePksfw2MotorOnOffMutation();

  const { data: motorStatus, isLoading } = useGetPksfw2MotorStatusQuery();

  // const { data: sensorStatus, isLoading } = useGetSensorSwitchStatusQuery();

  useEffect(() => {
    if (!isLoading) {
      // const sensorStatusData = sensorStatus?.data;
      const motorStatusData = motorStatus;

      if (motorStatusData) {
        if (motorStatusData?.motor_status == "0") {
          setOnOffMotorServerStatus(false);
        } else {
          setOnOffMotorServerStatus(true);
        }
      }
    }
  }, [isLoading, motorStatus]);

  useEffect(() => {
    if (!isLoading) {
      // const sensorStatusData = sensorStatus?.data;
      const motorStatusData = motorStatus;

      if (motorStatusData) {
        if (motorStatusData?.aerator_status == "0") {
          setOnOffAeratorServerStatus(false);
        } else {
          setOnOffAeratorServerStatus(true);
        }
      }
    }
  }, [isLoading, motorStatus]);

  const aeratorStatusChangeHandler = (e) => {
    let motorStatus = e.target.checked ? "1" : "0"; //0=false or 1=true
    setOnOffAeratorServerStatus(!onOffAeratorServerStatus);
    aeratorOnOff({
      switch: motorStatus,
    })
      .unwrap()
      .then((payload) => {
        if (motorStatus == "1") {
          Swal.fire(
            payload.message,
            "Aerator Status Switched to ON",
            "success"
          );
        } else {
          Swal.fire(
            payload.message,
            "Aerator Status Switched to OFF",
            "success"
          );
        }
      })
      .catch((error) =>
        Swal.fire({
          icon: "error",
          title: error.status,
          text: "Something went wrong!",
        })
      );
  };

  const motorStatusChangeHandler = (e) => {
    let motorStatus = e.target.checked ? "1" : "0"; //0=false or 1=true
    setOnOffMotorServerStatus(!onOffMotorServerStatus);
    pksfw2MotorOnOff({
      switch: motorStatus,
    })
      .unwrap()
      .then((payload) => {
        if (motorStatus == "1") {
          Swal.fire(payload.message, "Motor Status Switched to ON", "success");
        } else {
          Swal.fire(payload.message, "Motor Status Switched to OFF", "success");
        }
      })
      .catch((error) =>
        Swal.fire({
          icon: "error",
          title: error.status,
          text: "Something went wrong!",
        })
      );
  };

  return (
    <div>
      <div className={`container-fluid ${theme}`}>
        <div className="row">
          <div class="col-md-4">
            <div className="room-card card theme-background">
              <h4 className="room-name">Aerator Switch</h4>

              <div
                className="motor-on-off-switch"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "50px",
                }}
              >
                <h5 className={`off-label theme-text`}>Off</h5>
                <div className="only-switch">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={aeratorStatusChangeHandler}
                      checked={onOffAeratorServerStatus}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <h5 className={`theme-text`}>On</h5>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div className="room-card card theme-background">
              <h4 className="room-name">Motor Switch</h4>

              <div
                className="motor-on-off-switch"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "50px",
                }}
              >
                <h5 className={`off-label theme-text`}>Off</h5>
                <div className="only-switch">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={motorStatusChangeHandler}
                      checked={onOffMotorServerStatus}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <h5 className={`theme-text`}>On</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pksfw2MotorControl;
