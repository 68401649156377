import React, { useEffect } from "react";
import AcCard from "../ac-dashboard/AcCard";
import AcScheduleIndividualCard from "./AcScheduleIndividualCard";
import { useGetAcScheduleListQuery } from "../../../features/ac/acSchedule";

function AcScheduleCards({ selectedBranchId,onEditButtonClick }) {
  useEffect(() => {}, [selectedBranchId]);

  const { data: acScheduleList, isLoading: acScheduleListLoading } =
    useGetAcScheduleListQuery(selectedBranchId);

  return (
    <div>
      <div className="row">
        {acScheduleList?.data?.map((scheduleData) => {
          return (
            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
              <AcScheduleIndividualCard onEditButtonClick={onEditButtonClick} scheduleData={scheduleData}/>
            </div>
          );
        })}

        {/* <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <AcScheduleIndividualCard />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <AcScheduleIndividualCard />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <AcScheduleIndividualCard />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <AcScheduleIndividualCard />
        </div> */}
      </div>
    </div>
  );
}

export default AcScheduleCards;
