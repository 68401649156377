import React from "react";
import styles from "./acDashboard.module.css";
import temp_icon from "./assets/temp_icon.png";
import { IoMdArrowDropup } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
import { PiSnowflakeLight } from "react-icons/pi";
import { FiSun } from "react-icons/fi";
import { MdOutlineWaterDrop } from "react-icons/md";
import { PiFanDuotone } from "react-icons/pi";

function AcCard({ acInfo }) {
  return (
    <div>
      <div
        className={`${styles.card} ${styles.card_data_section} theme-background`}
      >
        <div className={`${styles.title_portion}`}>
          <div className={`${styles.title} theme-text`}>
            {acInfo?.peripheral_type}
          </div>
          <div className={`${styles.toggle}`}>
            <div>
              <label class="switch">
                <input type="checkbox" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div className={`theme-text ${styles.temperature_text}`}>
          Temperature
        </div>
        <div className={`${styles.icon_value_portion}`}>
          <div className={`${styles.icon}`}>
            <img src={temp_icon} alt="temp_icon"></img>
          </div>{" "}
          <div className={`${styles.value} theme-text`}>
            {acInfo?.temp || "---"}
          </div>
          <div className={`${styles.arrow_section}`}>
            <div>
              <IoMdArrowDropup size={40} color="#7CB9FD" />
            </div>
            <div>
              <IoMdArrowDropdown size={40} color="#7CB9FD" />
            </div>
          </div>
        </div>
        {/* <img src={coolIcon} style={{ color: "red" }}></img> */}
        <div className={`${styles.mode_container}`}>
          <div
            className={`${styles.mode_card} ${
              acInfo?.mode === "heat" ? styles.mode_card_active : ""
            }`}
          >
            <div>
              <FiSun
                // size="20"
                style={{ height: "21px", width: "21px" }}
              />
            </div>
            <div className={`${styles.mode_text}`}>Heat</div>
          </div>
          <div
            className={`${styles.mode_card} ${
              acInfo?.mode === "cool" ? styles.mode_card_active : ""
            }`}
          >
            <div>
              <PiSnowflakeLight
                // size="20"
                style={{ height: "21px", width: "21px" }}
              />
            </div>
            <div className={`${styles.mode_text}`}>Cool</div>
          </div>
          <div
            className={`${styles.mode_card} ${
              acInfo?.mode === "dry" ? styles.mode_card_active : ""
            }`}
          >
            <div>
              <MdOutlineWaterDrop
                // size="20"
                style={{ height: "21px", width: "21px" }}
              />
            </div>
            <div className={`${styles.mode_text}`}>Dry</div>
          </div>
          <div
            className={`${styles.mode_card} ${
              acInfo?.mode === "fan" ? styles.mode_card_active : ""
            }`}
          >
            <div>
              <PiFanDuotone
                // size="20"
                style={{ height: "21px", width: "21px" }}
              />
            </div>
            <div className={`${styles.mode_text}`}>Fan</div>
          </div>
        </div>
        {/* <div className={`${styles.footer_section}`}>
          <div
            className="theme-text"
            style={{ fontSize: "15px", fontWeight: "bold" }}
          >
            Auto Room Temperature System
          </div>
          <div className={`${styles.toggle}`}>
            <div>
              <label class="switch">
                <input type="checkbox" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div> */}
        <div className={`${styles.footer_section}`}>
          <div
            className="theme-text"
            style={{ fontSize: "15px", fontWeight: "bold" }}
          >
            Run Time
          </div>
          <div className={`theme-text`}>{acInfo?.duration}</div>
        </div>
      </div>
    </div>
  );
}

export default AcCard;
